<template>
  <Content>
    <template v-slot:topbar>
      <AddCustomer />
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="customers">
          <template v-if="customers.data.customers">
            <template v-if="customers.data.customers.length > 0">
              <div class="sm:rounded-md mb-3">
                <ul role="list" class="">
                  <ShowCustomer v-for="(customer, index) in customers.data.customers" :key="index" :item="customer" />
                </ul>
              </div>

              <Pagination :meta="customers.meta" :onPageChange="onPageChange" />
            </template>

            <template v-else>
              <p class="py-6 text-center dark:text-gray-300">
                Diemžēl nekas netika atrasts
              </p>
            </template>

          </template>

          <template v-else>
            <template v-if="customers.total > 0">
              <div class="sm:rounded-md mb-3">
                <ul role="list" class="">
                  <ShowItem v-for="(item, index) in customers.data" :key="index" :item="item"/>
                </ul>
              </div>
            </template>
            <template v-else>
              <p class="py-6 text-center dark:text-gray-300">
                Diemžēl nekas netika atrasts
              </p>
            </template>
          </template>

          <Pagination :meta="customers" :onPageChange="onPageChange" />
        </template>

        <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import Search from "@/services/Helpers/search";
import { mapGetters } from 'vuex'

import { defineAsyncComponent } from 'vue'

const ShowCustomer = defineAsyncComponent(() =>
  import('@/components/Customers/ShowCustomer'))
const AddCustomer = defineAsyncComponent(() =>
  import('@/components/Customers/AddCustomer'))
const Pagination = defineAsyncComponent(() =>
  import('@/components/Components/Pagination'))
const ShowItem = defineAsyncComponent(() =>
  import('@/components/Customers/Search/ShowItem'))

export default {
  name: "Customers",
  components: {
    AddCustomer,
    ShowCustomer,
    Pagination,
    ShowItem,
  },
  computed: {
    ...mapGetters({
      customers: 'customers',
      formsForDisplay: 'formsForDisplay',
      search: 'search',
    })
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  watch: {
    search: function () {
      this.searchCustomers()
    },
  },
  methods: {
    searchCustomers: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getCustomers', Search.getCurrentPage())
    }, 500),
    onSearchPageChange() {
      this.$store.dispatch('getCustomers', this.customers.current_page);
      Search.changePage(this.customers.current_page);
    },
    onPageChange(page) {
      this.$store.dispatch('getCustomers', page);
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddCustomerForm(){
      this.$store.dispatch('clearCustomerErrorMessages')
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    this.$store.dispatch('clearCustomers')

    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchCustomers()
    } else {
      this.$store.dispatch('getCustomers', Search.getCurrentPage())
    }
  },
}
</script>